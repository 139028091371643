.release_row_3 {
  padding-top: 3vmin;
  padding-bottom: 3vmin;
  width: 75%;
  display: flex;
  justify-content: space-between;
}

.releaseItem {
  width: 32%;
  position: relative;
  text-align: center;
}

.releaseArtwork {
  width: 50%;
  height: auto;
}

.releaseImage {
  position: relative;
  text-align: center;
  color: white;
  overflow: hidden;
  border-radius: 0px;
  width: 95%;
  height: auto;
}

.releaseImage img {
  height: auto;
  width: 100%;
  border-radius: 0px;
  filter: brightness(20%) grayscale(50%) blur(0.5px);
  transition: all 0.5s ease;
}

.releaseImage:hover img {
  filter: brightness(70%) grayscale(0%) blur(0px);
  transform: scale(1.1);
}

.releaseText {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: none;
}

.releaseText a {
  font-size: large;
  color: #fff !important;
  text-decoration: none;
}

.releaseText a * {
  color: #fff !important;
}

.releaseText a:hover * {
  color: #ff0048;
}

.releaseText a div.releaseTitle * {
  font-size: larger;
}

@media (max-width: 720px) {
  .release_row_3 {
    display: block;
  }
  .release_row_3 .releaseItem {
    display: block;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vmin;
    margin-bottom: 3vmin;
  }
}
