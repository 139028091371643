.latestReleases {
  margin-top: 6vmin;
  margin-bottom: 9vmin;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
}

.releaseCard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 75%;
}

.releaseCard .releaseCardInfo {
  height: 100%;
  margin-right: 3vmin;
  text-align: left;
  width: 30%;
  padding: 0;
}

.releaseCard .releaseCardVideo {
  width: 70%;
  height: auto;
}

.releaseCard .releaseCardVideo iframe {
  width: 100%;
  height: calc(300px + 6vmin);
}

.releaseCard .releaseCardInfo .releaseCardSocialIcons {
  text-align: left;
  margin-left: 0px;
  padding-left: 0px;
}

.releaseCard .releaseCardInfo .releaseCardSocialIcons .socialIconsList {
  list-style: none;
  padding-left: 0px;
}

.releaseCard .releaseCardInfo .releaseCardSocialIcons .socialIconsList li {
  display: inline-block;
  padding-right: 1.5vmin;
}

.releaseCard
  .releaseCardInfo
  .releaseCardSocialIcons
  .socialIconsList
  li
  a:hover
  .fa-custom-hover {
  color: #ff0048;
}

@media (max-width: 720px) {
  .latestReleases {
    margin-bottom: 6vmin;
    width: 100%;
  }
  .releaseCard {
    display: block;
    width: 75%;
    margin-bottom: 3vmin;
    margin-top: 3vmin;
    padding-top: 0;
  }
  .releaseCard .releaseCardInfo {
    display: block;
    width: 92%;
    margin-bottom: 3vmin;
    margin-top: 3vmin;
    padding-top: 0;
  }

  .releaseCard .releaseCardInfo .releaseArtwork {
    width: 100%;
  }
  .releaseCard .releaseCardVideo {
    display: block;
    width: 100%;
  }
  .releaseCard .releaseCardVideo iframe {
    width: 100%;
  }
}
