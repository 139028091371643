.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5vmin;
  padding-bottom: 0.5vmin;
  font-size: calc(10px + 3vmin);
  color: #fff;
  background-color: #282c34;
  width: 100%;
  margin-bottom: 1vmin;
}

.header-container {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vmin;
}

.header-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header-logo a {
  text-decoration: none;
}

.header-logo a h1 {
  margin: 0vmin;
  padding: 0vmin;
}

.header-container nav {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header-container nav ul {
  margin-top: 0vmin;
  margin-bottom: 0vmin;
  list-style: none;
  text-align: center;
  padding: 0;
}

.header-container nav ul li {
  display: inline-block;
  padding-left: 1.5vmin;
  padding-right: 1.5vmin;
}

.header-nav-link {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: medium;
}

.header-nav-link:hover {
  color: #ff0048;
}

.header-logo h1 {
  color: #fff;
}

@media (max-width: 720px) {
  .header-container {
    display: block;
  }
  .header-container .header-logo {
    display: block;
    margin-bottom: calc(10px + 1.5vmin);
  }
  .header-container nav {
    display: block;
    width: 100%;
  }
  .header-logo * {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
