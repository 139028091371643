* {
  color: #222;
}

a {
  text-decoration: none;
  color: #ff0048;
  text-transform: uppercase;
}

a:hover {
  text-decoration: none;
  color: #222;
}

.anchor {
  visibility: hidden;
}

.city {
  top: 0;
  margin-top: 0;
  padding-top: 0;
  /* background-image: url("./images/city-3814205.jpg"); */
  background-color: #333;
  background-attachment: fixed;
  background-size: cover;
}

.canvas {
  top: 0;
  margin-top: 0;
  padding-top: 0;
  background-attachment: fixed;
  background-size: cover;
}

.canvas .overlay {
  backdrop-filter: blur(100px);
}

.App {
  text-align: center;
  background-color: #ddd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.font-thin {
  font-weight: lighter !important;
}

.font-small {
  font-size: small;
}

.releaseHero {
  display: flex;
  align-items: top;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 3vmin;
}

.releaseHero .releaseHeroTitle h3 b a {
  font-size: xx-large;
  font-weight: 900;
  color: #ff0048;
  text-decoration: none;
}

.releaseHero .releaseHeroTitle h3 b a:hover {
  color: #fff;
}

.releaseHero .releaseHeroVideo {
  width: 100%;
  height: auto;
}

.releaseHero .releaseHeroVideo iframe {
  width: 100%;
  height: calc(600px);
}

.campaignContainer {
  margin-top: calc(10px + 3vmin);
  border-radius: 15px;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3); /* 21829c */
  margin-bottom: 3vmin;
  padding: 3vmin;
}

.campaignContainer .campaignInfo {
  width: 100%;
}

.campaignContainer .campaignInfo img {
  width: 100%;
}

.campaignContainer .campaignButtons {
  width: 100%;
  text-align: left;
}

.campaignButtons .serviceButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.7);
  height: calc(40px + 3vmin);
  margin-bottom: 1.5vmin;
}

.campaignButtons .serviceButton .serviceIcon {
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
}

.campaignButtons .serviceButton .serviceName {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 75%;
  font-size: x-large;
  text-transform: uppercase;
}

@media (max-width: 720px) {
  .campaignContainer {
    display: block;
    width: 85%;
  }
}
